<template>
  <section id="agendador">
    <b-breadcrumb
      :items="breadcrumb"
    />

    <b-row>
      <b-col cols="12">
        <b-card>
          <b-row
            class="header-card"
            align-v="center"
          >
            <b-col>
              <h4>
                Consultores
              </h4>
            </b-col>

            <b-col class="text-right">
              <b-button
                class="mb-2"
                :to="{ name: 'agendador-user-edit', params: { id: 0 } }"
              >
                Novo consultor
              </b-button>
            </b-col>
          </b-row>

          <b-table
            :busy="isLoading || isRemoving"
            fixed
            :items="results"
            :fields="resultFields"
            sort-icon-left
            no-local-sorting
          >

            <template #head(name)>
              <div class="text-nowrap text-center">
                Consultor
              </div>
            </template>

            <template #head(events)>
              <div class="text-nowrap text-center">
                Eventos
              </div>
            </template>

            <template #head(actions)>
              <div class="text-nowrap text-center">
                Ações
              </div>
            </template>

            <template #cell(name)="row">
              <span>{{ row.item.name }}</span><br>
              <span>{{ row.item.email }}</span>
            </template>

            <template #cell(events)="row">
              <div
                v-for="event in row.item.events"
                :key="event.id"
              >
                <span class="text-bold">{{ event.name }}</span><br />
                <span>{{ event.slug_url }}</span><br />
                <!-- eslint-disable vue/no-v-html -->
                <p
                  class="mb-1"
                  v-html="event.string"
                ></p>
              </div>
            </template>

            <template #cell(actions)="row">
              <div class="text-nowrap text-center">
                <b-button
                  variant="flat-primary"
                  :to="{ name: 'agendador-user-edit', params: { id: row.item.id } }"
                  :disabled="isLoading || isRemoving"
                  title="Editar"
                >
                  <feather-icon
                    icon="EditIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </b-button>

                <b-button
                  variant="flat-primary"
                  title="Remover"
                  :disabled="isLoading || isRemoving"
                  @click="removeConsultant(row.item)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </b-button>
              </div>
            </template>

          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BBreadcrumb, BButton, BCard,
  BCol,
  BRow, BTable,
} from 'bootstrap-vue'
import SchedulerService from '@/services/schedulerService'
import toasts from '@/mixins/toast'

export default {
  components: {
    BCard,
    BButton,
    BTable,
    BRow,
    BCol,
    BBreadcrumb,
  },

  mixins: [toasts],

  props: {
    managerIdFromProps: {
      type: Number,
      default: null,
    },
    paymentDateFrom: {
      type: String,
      default: null,
    },
    paymentDateTo: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      breadcrumb: [
        {
          text: 'Projetos',
          active: true,
        },
        {
          text: 'Agendador',
          to: { name: 'agendador-list' },
        },
      ],
      results: [],
      resultFields: [
        { key: 'name', sortable: true },
        { key: 'events', sortable: true },
        { key: 'actions', sortable: false, thStyle: 'width: 180px' },
      ],
      userData: null,
      isLoading: false,
      isRemoving: false,
    }
  },

  created() {
    const userDataString = localStorage.getItem('userData')
    this.userData = JSON.parse(userDataString)

    this.getConsultants()
  },

  methods: {
    removeConsultant(consultant) {
      this.$swal({
        title: `Remover Consultor '${consultant.name}'?`,
        text: 'Esta ação não poderá ser desfeita!',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sim, remover!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isRemoving = true
          SchedulerService.deleteConsultant(consultant.id).then(response => {
            if (response.data.data.success) {
              this.toastSuccess('Sucesso!', 'Consultor removido!')
              this.getConsultants()
            } else {
              this.toastDanger('Erro!', 'Erro ao remover o consultor')
            }
          }).catch(() => {
            this.toastDanger('Erro!', 'Erro ao remover o consultor')
          }).finally(() => {
            this.isRemoving = false
          })
        }
      }).catch(() => {
        this.toastDanger('Erro!', 'Erro ao remover o consultor')
      })
    },
    getConsultants() {
      this.isLoading = true
      SchedulerService.getConsultants().then(response => {
        if (response.data !== null) {
          this.results = response.data.data.consultants
          this.results = this.results.map(user => ({
            ...user,
            events: user.events.map(event => ({
              ...event,
              string: event.string.split('\n').join('<BR>'),
            })),
          }))
        }
      }).finally(() => {
        this.isLoading = false
      })
    },
  },
}
</script>
